<!-- Start of Consent Management -->
// window event listener that checks for consent
window.addEventListener("ucEvent", function (e) {

    // Actions for updating mParticle Consent
    const acceptableActions = ["onAcceptAllServices", "onDenyAllServices", "onUpdateServices"];

    // triggered on PageLoad
    if (e.detail && e.detail.event == "consent_status" && e.detail.action === "onInitialPageLoad") {
        let consent_choice_essential = e.detail?.ucCategory?.essential;
        let consent_choice_functional = e.detail?.ucCategory?.functional;
        let consent_choice_marketing = e.detail?.ucCategory?.marketing;

        // log consent status
        //console.log('Essential Consent is '+ consent_choice_essential + '; Functional Consent is ' + consent_choice_functional + '; Marketing Consent is '+ consent_choice_marketing);

        // check for functional cookie consent
        if (consent_choice_functional == true) {
            window.clarity('consent');
        } else {
            window.clarity('consent', false);
        };

        // check for marketing cookie consent
        if (consent_choice_marketing == true) {
            pintrk('setconsent', true);
        } else {
            pintrk('setconsent', false);
        };

    };

    // Triggered for all consent buttons
    if (e.detail && e.detail.event == "consent_status" && acceptableActions.includes(e.detail.action)) {
        let consent_choice_essential = e.detail?.ucCategory?.essential;
        let consent_choice_functional = e.detail?.ucCategory?.functional;
        let consent_choice_marketing = e.detail?.ucCategory?.marketing;

        // log consent status
        //console.log('Essential Consent is '+ consent_choice_essential + '; Functional Consent is ' + consent_choice_functional + '; Marketing Consent is '+ consent_choice_marketing);

        // check for functional cookie consent
        if (consent_choice_functional == true) {
            window.clarity('consent');
        } else {
            window.clarity('consent', false);
        };

        // check for marketing cookie consent
        if (consent_choice_marketing == true) {
            pintrk('setconsent', true);
        } else {
            pintrk('setconsent', false);
        };


        // Create consents for different purposes (in this case, location and parental consent purposes)

        var essential_consent = mParticle.Consent.createGDPRConsent(
            consent_choice_essential, // Consent Choice
            Date.now(), // Timestamp
            "data privacy agreement website", // Document
            "Berlin", // Location
            "mp_deviceid:" + window.deviceID // Web Device Stamp
        );
        var functional_consent = mParticle.Consent.createGDPRConsent(
            consent_choice_functional, // Consent Choice
            Date.now(), // Timestamp
            "data privacy agreement website", // Document
            "Berlin", // Location
            "mp_deviceid:" + window.deviceID // Web Device Stamp
        );
        var marketing_consent = mParticle.Consent.createGDPRConsent(
            consent_choice_marketing, // Consent Choice
            Date.now(), // Timestamp
            "data privacy agreement website", // Document
            "Berlin", // Location
            "mp_deviceid:" + window.deviceID // Web Device Stamp
        );

        // Add to your consent state
        var consentState = mParticle.Consent.createConsentState();
        consentState.addGDPRConsentState("essential", essential_consent);
        consentState.addGDPRConsentState("functional", functional_consent);
        consentState.addGDPRConsentState("marketing", marketing_consent);

        user.setConsentState(consentState);

    };
});
<!-- End of Consent Management -->

<!-- Start of Tracking -->
//tracks page views
window.addEventListener('heyflow-screen-view', (event) => {
    const path = window.location.hash.toString();
    let properties = window.FF.constructProperties(path);
    let customFlags = window.FF.constructCustomFlags(path);

    if (path in window.FF.pathMap) {
        // Log the PageView event
        mParticle.logPageView(properties['pageName'], properties, customFlags);
        mParticle.logEvent("TT Page View", mParticle.EventType.Other, properties, customFlags);
    };

    if (event.detail.screenName == 'download') {
        // Put the generated OneLink URL behind CTA buttons
        document.getElementById("andrd_link").setAttribute("href", window.result_url);
        document.getElementById("ios_link").setAttribute("href", window.result_url);
        // Optionally - Create QR code from the generated OneLink URL
        window.AF_SMART_SCRIPT.displayQrCode("qr_download");
        document.getElementById('fallback_QR').setAttribute('hidden', 'hidden');
        // Optionally - fire an impression.
        // The impression will fire to https://impressions.onelink.me//....
        setTimeout(() => {
            window.AF_SMART_SCRIPT.fireImpressionsLink();
        }, 1000);
    };
});

// tracks CTA clicks in the Body
window.addEventListener('Body_CTA_Clicked', (event) => {
    const path = window.location.hash.toString();
    let properties = window.FF.constructProperties(path);
    let customFlags = window.FF.constructCustomFlags(path);
    properties['cta'] = "Kostenlos starten";
    properties['section'] = "Body";
    mParticle.logEvent("Click", mParticle.EventType.Other, properties, customFlags);
    pintrk('track', 'addtocart');
});

// tracks CTA clicks in the header
window.addEventListener('Header_CTA_Clicked', (event) => {
    const path = window.location.hash.toString();
    let properties = window.FF.constructProperties(path);
    let customFlags = window.FF.constructCustomFlags(path);
    properties['cta'] = "Kostenlos starten";
    properties['section'] = "Navigation Bar";
    mParticle.logEvent("Click", mParticle.EventType.Other, properties, customFlags);
    pintrk('track', 'addtocart');

});

// tracks the 'submit' event
window.addEventListener('heyflow-submit', (event) => {
    const path = window.location.hash.toString();
    let properties = window.FF.constructProperties(path);
    let customFlags = window.FF.constructCustomFlags(path);
    properties['email'] = event.detail.fieldsSimple.email;
    properties['funnel'] = "Alltag";
    
    //log Signup Event
    mParticle.logEvent('Heyflow Signup', mParticle.EventType.Other, properties, customFlags);
    pintrk('track', 'signup');

    //Update email to mParticle
    let identityRequest = {
        userIdentities: {
            email: properties['email']
        }
    };
    let identityCallback = function (result) {
        if (result.getUser()) {
            //proceed
        }
    };
    mParticle.Identity.modify(identityRequest, identityCallback);

});
<!-- End of Tracking -->